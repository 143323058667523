import React from 'react'

import avatar from '../assets/images/avatar.png'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="#" className="image avatar"><img src={avatar} alt="" /></a>
                    <h1><strong>Arbor FX</strong> <br />
                    Adaptive trading technology.</h1>
                </div>
            </header>
        )
    }
}

export default Header