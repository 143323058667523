import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

import fullImg04 from '../assets/images/fulls/img04.jpg'
import fullImg08 from '../assets/images/fulls/img08.jpg'

class HomeIndex extends React.Component {

    render() {
        const siteTitle = "Arbor FX"
        const siteDescription = "Arbor FX"

        return (
            <Layout>
                <Helmet>
                        <title>{siteTitle}</title>
                        <meta name="description" content={siteDescription} />
                </Helmet>
                <div id="main">
                    <div>
                        <header className="major">
                            <h2>Welcome</h2>
                        </header>
                        <p>Founded in 2016, Arbor FX is a privately held company specializing in adaptive trading algorithms and technologies.  Our belief is that markets are continually evolving and that corresponding algorithmic approaches toward trading must adapt accordingly.  Contact information: <a href="mailto:info.arborfx.com">info.arborfx.com</a>.</p>
                    </div>
                    <div>
                        <img src={fullImg08} alt=""/>
                    </div>
                    <div>
                    © 2016 - 2019 Arbor FX, LLC. All rights reserved.
                    </div>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex